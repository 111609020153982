<template>
  <div>
    <div class="container">
      <div class="float_right">
        <p><a @click="()=>{
              contentHtml = detail.rule
              contentHtmlModal = true
            }"><img :src="oss+'video/rulesBtn.png'"/><span>规则</span></a></p>
        <p><a @click="goService(detail.kefu_url)"><img :src="oss+'video/serviceBtn.png'"/><span>客服</span></a></p>
        <p><router-link to="/duanju/automaticRefund" v-if="is_open_refund"><img :src="oss+'video/refundIcon.png'"/><span>极速退款</span></router-link></p>
      </div>

      <van-row class="video" :gutter="20">
        <van-col span="8" v-for="(item,key) in videoList" :key="key"><img  :src="item" /></van-col>
      </van-row>

      <div class="countDown">
        <van-count-down :time="time" millisecond>
          <template #default="timeData">
            过时不候，请尽快领取
            <span>{{ timeData.minutes }}:{{ timeData.seconds }}:{{moment(timeData.milliseconds).format("SS")}}</span>
          </template>
        </van-count-down>
      </div>

      <div class="paybtn"><van-button round block @click="onsubmit"></van-button></div>

      <div class="payList" v-if="payList.length">
        <van-radio-group v-model="json.payWay">
          <van-cell-group :border="false">
            <van-cell v-for="item in payList" :key="item.key">
              <template #icon><img :src="item.img" height="20"/></template>
              <template #title>
                {{item.name}}
              </template>
              <template #right-icon>
                <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>

      <div class="foot">
        <p>{{siteConfig.name}}</p>
        <p>{{siteConfig.icp}}</p>
        <p>{{siteConfig.address}}</p>
        <p>客服电话：<a :href="'tel:'+siteConfig.phone">{{siteConfig.phone}}</a></p>
      </div>

    </div>


    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="content_main">
        <div class="title">活动规则</div>
        <div class="body" v-html="contentHtml"></div>
      </div>
      <div class="action">
        <van-button type="default" block round @click="()=>{contentHtmlModal = false}">我知道了</van-button>
      </div>
    </van-popup>

  </div>

</template>
<script>
  import moment from 'moment'
  import {getordernum} from '@/utils/utils'
  import {GetPayConfig,CreateOrder, GetOrderStatus} from '../services/services'
  export default {
    name:'duanju',
    components: {},
    data () {
      return {
        sessionKey:'video1_order',
        contentHtml:'',
        detail:{},
        payList:[],
        json:{
          money:'',
          type:'',
          order_sn:'',
          payWay:14,
          url:location.href
        },
        time:5 * 60 * 1000,
        count:0,
        maxcount:10,
        timer:null,
        contentHtmlModal:false,
        is_open_refund:false,
        siteConfig:{},
        videoList:[this.oss+'video/1.png',this.oss+'video/2.png',this.oss+'video/3.png']
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
    },
    filters:{

    },
    computed:{
    },
    mounted(){
      window.addEventListener(
        "pageshow", (event)=> {
          if(event.persisted || window.performance && window.performance.navigation.type == 2){
            location.reload()
            return false
          }
        },
        false
      )
      setTimeout(()=>{
        if(sessionStorage.getItem(this.sessionKey) && this.json.order_sn == ''){
          let order_info = JSON.parse(sessionStorage.getItem(this.sessionKey))
          this.json={
            ...this.json,
            ...order_info
          }
        }

        if(this.json.order_sn != ''){
          this.findOrderStatus()
        }
      },1000)
      this.findpayConfig()
    },
    methods:{
      moment,
      goService(url){
        location.href=url
      },
      findpayConfig(){
        GetPayConfig({type:this.json.type}).then(result => {
          if(result.data.code==1){
            const {payConfig,companyInfo,money} = result.data.data
            this.detail = result.data.data
            this.siteConfig = companyInfo

            this.payList=payConfig
            this.json = {
              ...this.json,
              money:this.detail.money
            }
            this.is_open_refund = this.detail.is_open_refund

          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      onsubmit(){
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        this.getCreateOrder({
          ...this.json,
          order_sn:order_sn
        })
      },
      getCreateOrder(res){
        CreateOrder(res).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem(this.sessionKey,JSON.stringify(res))

            setTimeout(()=>{
              location.href=result.data.data
            },1000)

            setTimeout(()=>{
              this.findOrderStatus()
            },3000)
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      findOrderStatus(status){
        GetOrderStatus({order_sn:this.json.order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.$toast.success('支付成功')
            this.clearTime()
            setTimeout(()=>{
              this.finishPay()
            },1000)
          }else{
            if(status){
              this.$toast.success(result.data.msg)
              this.clearTime()
              return
            }
            this.$dialog.confirm({
              title: '提示',
              message: '确认是否已完成支付',
              confirmButtonText:'已完成',
              cancelButtonText:'未支付',
              closeOnClickOverlay:true
            }).then(() => {//已支付完成检测
              this.findOrderStatus(true)
            }).catch((res) => {
              if(res == 'overlay'){//关闭弹层
                this.clearTime()
              }else{//重新支付
                this.getCreateOrder(this.json)
              }
            })
            if(this.timer == null && this.count<this.maxcount){
              this.getTimerStatus()
            }
          }
        })
      },
      getTimerStatus(){
        this.timer = setInterval(() => {
          if(this.count>=this.maxcount){
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus()
        },1*1000)
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem(this.sessionKey)
      },
      finishPay(){
        const {wechat_service_url,kefu_url,money} = this.detail
        this.$router.push({path:'/duanju/success',query:{wechat_url:escape(wechat_service_url),kefu_url:escape(kefu_url),money:money,order_sn:this.json.order_sn}})
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:#fccc9c url('@{static-dir}video/video1_bg.png') no-repeat;background-size:100% auto;padding:86px 40px 0px;
    .float_right{top:50px;background:#fff;border-radius:8px 0px 0px 8px;padding:0px 4px;
      p{padding:8px 0px;line-height:14px;border-bottom:1px solid #f1f1f1;
        &:last-child{border-bottom:none;}
        a{font-size:12px;width:24px;text-align:center;display:inline-block;text-align:center;
          img{width:18px;}
          span{display:inline-block;color:#333;}
        }
      }
    }

    .video{width:90%;margin:200px auto 0px;
      img{width:100%}
    }
    .countDown{margin-top:56px;background:#FEC598;border-radius:100px;padding:6px 0px 6px 40px;position:relative;
      .van-count-down{font-size:14px;font-weight:700;
        span{color:#E90003;}
      }
      &::after{content:'';width:32px;height:40px;background:url('@{static-dir}video/clockIcon.png') no-repeat;background-size:100% auto;position:absolute;left:0px;top:-5px;z-index:1;}
    }
    .paybtn{height:88px;margin-top:18px;
      .van-button{width:84%;background:url('@{static-dir}video/payBtn.png') no-repeat;background-size:100% auto;height:74px;animation:2s ease-in-out 0s infinite normal none running scaleDraw;margin:auto;border:none;}
    }
    .payList{height:90px;
      .van-cell-group{background:#F1F1F1;border-radius:80px;}
      .van-cell{align-items:center;}
    }

  }

  .foot{color:#B42020;padding-bottom:24px;text-align:center;font-size:16px;margin-top:20px;
    p{padding:2px 0px;}
  }
  .contentPop{width:80%;
    .action{

    }
  }
  @keyframes scaleDraw{
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.2);
    }
  }
</style>
