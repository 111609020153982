import {
  payconfig,createOrder,getOrderStatus,dzRefund
} from './api'
import {request, METHOD} from '@/utils/request'

export async function GetPayConfig(params) {
  return request(payconfig, METHOD.GET,params)
}

export async function CreateOrder(params) {
  return request(createOrder, METHOD.POST,params)
}
export async function GetOrderStatus(params) {
  return request(getOrderStatus, METHOD.POST,params)
}
export async function PostDzRefund(params) {
  return request(dzRefund, METHOD.POST,params)
}
